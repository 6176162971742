.footer {
    max-width: 350px;
    width: 90%;
    margin: 0 auto;
    padding-top: 5px;
    border-top: 1px solid #fff;
    margin-top: 38px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;



    &_text {
        text-decoration: none;
        color: #FFF;
        font-family: Lexend Mega;
        font-size: 9px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}